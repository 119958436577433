import './testimonials.component.css';

const Testimonials: React.FC<{
  style?: React.CSSProperties;
}> = ({ style }) => {
  return (
    <div className="faq-container" style={style}>
      <div className="title" style={{ marginBottom: '10px' }}>
        What They're
        <br />
        Saying About Us
      </div>

      <div className="description" style={{ marginBottom: '20px' }}>
        Jenny Scordamaglia, MiamiTV
      </div>

      <iframe
        width="400"
        src="https://www.youtube.com/embed/V1Yrg2AWFzo?si=BBIRPIiaQHWBKfsS"
        title="Swoons - Jenny Scordamaglia"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        style={{
          border: 'none',
          borderRadius: '20px',
          maxWidth: '90%',
          aspectRatio: 9 / 16,
        }}
      ></iframe>
    </div>
  );
};

export default Testimonials;
