import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Highlights from './highlights/highlights.component';
import FAQ from './faq/faq.component';
import Lander from './lander/lander.component';
import Join from './join/join.component';
import IndexFooter from './index-footer/index-footer.component';
import { useAuthStore } from '../../../stores';

import './index.component.css';
import Testimonials from './testimonials/testimonials.component';

const Index: React.FC = () => {
  const navigate = useNavigate();

  const { token, guestToken } = useAuthStore();

  useEffect(() => {
    if (!!token && token !== guestToken) navigate('/messages');
  }, [token, guestToken]);

  return (
    <div className="index-container">
      <Lander style={{ marginBottom: '34px' }} />

      <Highlights style={{ marginBottom: '68px' }} />

      <Testimonials />

      <FAQ />

      <Join style={{ marginBottom: '68px' }} />

      <IndexFooter style={{ marginBottom: '68px' }} />
    </div>
  );
};

export default Index;
